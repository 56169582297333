/*********************************************************************/
$(document).ready(function(){
    $('#authorizationText').scroll(function () {
        if (($(this)[0].scrollHeight - $(this)[0].scrollTop) === $(this)[0].clientHeight){
            $('#agreeExpediteTerm').prop('disabled', false);
            $('#agreeExpediteCommunications').prop('disabled', false);
        }
    });

    $('#expediteRegisterFormId .submit-button').on(
        'click', function(e){
            e.preventDefault();
            if ($('#summary_statement').val() !== '' || (typeof $('input:radio[name="insuranceCoverageTypeId"]:checked').val() === 'undefined')) {
                $( "#expediteRegisterFormId" ).submit();
            } else {
                $('#myModal').modal('show');
            }
        }
    );

    $('#expediteRegisterFormId .continue-button').on(
        'click', function(e){
            e.preventDefault();
            $( "#expediteRegisterFormId" ).submit();
        }
    );
});
